.dashboard-wrapper {
  width: 80%;
  margin: 10px auto;
  flex-direction: column;
}

.dashboard-wrapper .quick-actions {
  margin: 7px 0;
}

.dashboard-wrapper .quick-actions .quick-actions-header h1 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.dashboard-wrapper .quick-actions .quick-actions-body {
  padding: 20px 5px;
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  width: 50%;
  border-radius: 10px;
  margin-top: 8px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.dashboard-wrapper .quick-actions .quick-actions-body .action-btn {
  padding: 9px 15px;
  margin: 5px 10px;
  outline: none;
  border: none;
  background: transparent;
  color: #0f172a;
  border: 1px solid #0f172a;
  font-weight: bold;
  border-radius: 3px;
}
.dashboard-wrapper .quick-actions .quick-actions-body .action-btn a {
  color: inherit;
}
.dashboard-wrapper .quick-actions .quick-actions-body .action-btn span {
  margin: 0 8px;
}

.dashboard-wrapper .quick-actions .quick-actions-body .action-btn:hover {
  background: #0f172a;
  color: white;
  border: 1px solid #0f172a;
}
.quick-actions .quick-actions-body .form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}
.quick-actions .quick-actions-body .form span {
  margin: 0 4px;
}

.quick-actions .quick-actions-body .form .form-group {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.quick-actions .quick-actions-body .form .form-group.package-item {
  background: white;
  padding: 5px;
}

.quick-actions .quick-actions-body .form .form-group.package-item p {
  margin: 8px 0;
}

.quick-actions .quick-actions-body .form .form-group label {
  font-weight: bold;
  margin: 7px 2px;
}

.quick-actions .quick-actions-body .form .form-group .form-control {
  padding: 10px;
  width: 100%;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: white;
}

.quick-actions .quick-actions-body .form .form-group-pair {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.quick-actions .quick-actions-body .form .form-group-pair .form-group {
  width: 50%;
  padding: 5px;
}
.quick-actions .quick-actions-body .form .form-group .form-submit {
  padding: 12px 10px;
  width: 99%;
  border: none;
  outline: none;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background: #000;
  margin: 4px;
}
.quick-actions .quick-actions-body .form .form-group .form-submit-outline {
  padding: 12px 10px;
  width: 99%;
  border: 1px solid #000;
  outline: none;
  border-radius: 4px;
  color: #000;
  font-weight: bold;
  background: transparent;
  margin: 4px;
}

.quick-actions .quick-actions-body .form .form-group .form-pair {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}
.quick-actions
  .quick-actions-body
  .form
  .form-group
  .form-pair
  button.form-control-2 {
  background: #0f172a;
  color: white;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quick-actions
  .quick-actions-body
  .form
  .form-group
  .form-pair
  .form-control-2 {
  width: 50%;
  padding: 10px;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: white;
  margin: 0 4px;
}

.form-pair button {
  background: #fb8500;
}

.appointments-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  width: 100%;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.flex {
  dislay: flex;
  justify-content: center;
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.schedule {
  outline: none;
  border: none;
  background: #e2e8f0;
  padding: 8px 12px;
  margin: 3px;
  border-radius: 5px;
  cursor: pointer;
}

.appointments-wrapper .appointment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  border-bottom: 1px solid #cbd5e1;
}

.services-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  width: 100%;
}

.services-wrapper .service-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 5px 0;
  background: #fffcfc;
}

.services-wrapper .service-item .item-btn {
  outline: none;
  border: none;
  padding: 5px 15px;
  background: #0f172a;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.unavailable {
  text-decoration: strike-through;
}

.appointments-wrapper .appointment-item .appointment-date {
  color: #fb9230;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

.popup-container {
  position: absolute;
  top: 25%;
  z-index: 10;
  border: 1px solid red;
  background: #fff;
  margin-right: 20px;
  border-radius: 5px;
  box-shadow: 2px 4px 8px 2px #ccc;
}
.popup-container .popup-header {
  background: #e51212;
  padding: 10px;
  color: white;
}

.popup-container .popup-body {
  padding: 20px 10px;
}
.popup-container .popup-body .popup-btns {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 1000px) {
  .dashboard-wrapper {
    width: 90%;
    margin: 20px auto;
  }
  .dashboard-wrapper .quick-actions .quick-actions-body {
    width: 70%;
  }
}

@media screen and (max-width: 840px) {
  .dashboard-wrapper .quick-actions .quick-actions-body {
    width: 100%;
    padding: 10px 5px;
  }
  .dashboard-wrapper .quick-actions .quick-actions-body .action-btn {
    /* padding: 10px; */
    /* width: 100%; */
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
