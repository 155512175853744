*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #f1f5f9;
}

a {
  text-decoration: none;
  color: inherit;
}

/* .plans-container { */
/*   width: 100%; */
/*   height: 100vh; */
/*   margin: auto; */
/*   color: #fb8500; */
/*   display: flex; */
/*   justify-content: center; */
/*   align-items: center; */
/*   flex-direction: column; */
/* } */
.plans-container {
  width: 80%;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.plans-container .plans-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 10px;
  color: black;
}
.plans-container .plans-wrapper h1 {
  color: #fb8500;
}

.plans-container .plans-wrapper .center-items {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.plans-container .plans-wrapper .plan-item {
  /* padding: 30px 50px; */
  padding: 20px 30px;
  margin: 10px;
  width: 270px;
  border-radius: 5px;
  border: 3px solid #fb8500;
  /* background: #fb8500; */
  background: black;
  color: white;
  cursor: pointer;
  transition: transform 0.25s ease-in;
}
.plans-container .plans-wrapper .plan-item .plan-header {
  color: #fb8500;
}

.plans-container .plans-wrapper .plan-item:hover {
  transform: scale(1.03);
}

.plans-container .plans-wrapper .plan-item .plan-body {
  padding: 10px 5px;
  margin: 10px 0;
}
.plans-container .plans-wrapper .plan-item .plan-services {
  border-top: 2px dotted #ccc;
  padding: 10px 5px;
  margin: 10px 0;
}

@media screen and (max-width: 1240px) {
  .plans-container .plans-wrapper {
    width: 90%;
  }

  .plans-container .plans-wrapper h1 {
    margin: 20px 0;
    color: #fb8500;
  }
  .plans-container .plans-wrapper .plan-item {
    z-index: 10;
  }
}

@media screen and (max-width: 1238px) {
  .plans-container .plans-wrapper {
    width: 90%;
    flex-direction: column;
  }

  .plans-container .plans-wrapper h1 {
    margin: 20px 0;
  }

  .plans-container .plans-wrapper .plan-item {
    z-index: 10;
  }
}

@media screen and (max-width: 990px) {
  .plans-container {
    /* flex-direction: column; */
    /* height: auto; */
  }

  .plans-container .plans-wrapper .plan-item {
    z-index: 10;
  }
}
