*,
*::before,
*::after {
  box-sizing: border-box;
}

main .container {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px;
}

.container .heading .contents {
  padding: 10px;
  width: 85%;
}
.container .heading .contents h1 {
  text-align: center;
}
.container .heading .contents p {
  margin: 10px 0;
}

.container .heading .logo {
  width: 13rem;
  height: 13rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-schedule {
  background: black;
  color: white;
}

.container .heading h1 {
  color: #fb8500;
}

.container .heading .logo img {
  width: 100%;
  height: 100%;
}

.container .form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container .form form .form-group {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
}

.form-group .add-on {
  display: flex;
  align-items: center;
}

.form-group .add-on p {
  margin: 0 5px;
  color: #fb8500;
  cursor: pointer;
}

.form-group .add-on p:hover {
  text-decoration: underline;
}

.container .form form .form-group p .form-link {
  color: #fb8500;
  font-weight: bold;
}

.container .form form .form-group .form-control {
  width: 100%;
  padding: 12px;
  margin: 5px 0;
  border: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.btn {
  outline: none;
  border: none;
  padding: 10px;
  margin: 6px 0;
  background: #0f172a;
  color: white;
  font-size: 1.2rem;
  border-radius: 2px;
}

.btn-del {
  color: red;
  border: 1px solid red;
  background: transparent;
}

.btn-del:hover {
  color: white;
  background: red;
}

.btn-sm {
  font-size: 0.9rem;
  padding: 5px 10px;
  margin: 1px 0;
  border-radius: 4px;
}

.btn-sm-dark {
  background: #000;
  color: white;
}

.btn-dark {
  outline: none;
  border: none;
  padding: 10px;
  margin: 6px 0;
  background: #333;
  color: ivory;
  font-size: 1.1rem;
  border-radius: 4px;
}
.btn-block {
  width: 100%;
}
button[disabled],
button:disabled {
  color: black;
  background: grey;
}

a {
  text-decoration: none;
}

.make-appointment-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.make-appointment-btn p {
  font-size: 1.1rem;
  color: #3f3c39;
  font-weight: 400;
  text-align: center;
}

#tos {
  width: 30px;
  height: 30px;
  margin: 0 4px;
}

@media screen and (min-width: 480px) {
  #tos {
    width: 15px;
    height: 15px;
  }
}

.make-appointment-btn .links {
  text-decoration: none;
  color: black;
  font-weight: bolder;
}

@media screen and (min-width: 800px) {
  .container .form form .form-group .form-control {
    width: 300px;
    padding: 10px;
    margin: 5px 0;
  }
  .btn-block {
    width: 300px;
  }
  .container .heading .logo {
    width: 13rem;
    height: 13rem;
  }
}
