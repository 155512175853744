nav {
  width: 100%;
  height: 4.5rem;
  background: #020617;
  color: #f1f5f9;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  cursor: pointer;
}

nav a {
  text-decoration: none;
}

.wrapper {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav .wrapper .logo {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  font-weight: bold;
}
nav .wrapper .logo a {
  color: #f1f5f9;
}
.wrapper .burger-menu {
  display: none;
}

nav .wrapper ul {
  list-style: none;
  display: flex;
}

nav .wrapper ul li {
  margin: 0 5px;
}

nav .wrapper ul li button {
  outline: none;
  border: none;
  background: transparent;
  font-size: 1.04rem;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #cbd5e1;
}
nav .wrapper ul li button:hover {
  background: #0f172a;
  color: white;
}

nav .wrapper ul li button span {
  padding: 0 5px;
}

@media screen and (max-width: 1000px) {
  .wrapper {
    width: 90%;
  }
  nav .wrapper ul li {
    margin: 0 3px;
  }
  nav .wrapper ul li button span {
    padding: 0 3px;
  }
}

@media screen and (max-width: 840px) {
  .wrapper .burger-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    cursor: pointer;
    padding: 10px;
  }

  nav .wrapper ul {
    position: absolute;
    width: 300px;
    padding: 15px 0;
    top: 4rem;
    bottom: 0;
    z-index: 2;
    right: 0;
    flex-direction: column;
    background: #020617;
    animation: menu-animation 0.15s;
    /* height: 100vh; */
  }

  nav .wrapper ul li button {
    color: white;
  }
  nav .wrapper ul li {
    margin: 6px 15px;
  }
  nav .wrapper ul li button span {
    padding: 0 10px;
  }
}

@keyframes menu-animation {
  from {
    right: -150px;
  }
  to {
    right: 0px;
  }
}
